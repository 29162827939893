(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

function notify(text, notificationType) {
	var buttons = [];

	buttons.push({
		addClass: 'btn btn-danger btn-close', text: 'x', onClick: function onClick($noty) {
			$noty.close();
		}
	});

	switch (notificationType) {
		case 'item_added':
			buttons.push({
				addClass: 'btn btn-light',
				text: 'Tovább a kosárhoz',
				onClick: function onClick($noty) {
					$noty.close();
					document.location.href = '/cart';
				}
			});
			break;
		default:
	}

	var n = noty({
		layout: 'topRight',
		theme: 'shop',
		type: notificationType,
		text: text,
		animation: {
			open: 'animated bounceInRight', // Animate.css class names
			close: 'animated bounceOutRight', // Animate.css class names
			easing: 'swing', // unavailable - no need
			speed: 500 // unavailable - no need
		},
		buttons: buttons
	});
}

function showProductModal(itemId) {
	$.get('/products/' + itemId, function (res) {
		var $modal = $('#productDetailsModal');

		if (res.has_discount == true) {
			var $discountDiv = '<div class="discount-value text-center">-' + res.highestDiscountValue + '</div>';

			$modal.find('.modal-content').addClass('has-discount');
			$modal.find('.discount-value').remove();
			$modal.find('.product-item').before($discountDiv);

			$modal.find('.prices').empty().append('<p class="price old_price">' + res.price + '</p>' + '<p class="price discounted_price">' + res.dicountedPrice + '</p>');
		} else {
			$modal.find('.modal-content').removeClass('has-discount');
			$modal.find('.discount-value').remove();
			$modal.find('.prices').empty().append('<p class="light-grey">termék ára: <span style="color:#c75868; font-weight: bold;" class="price">' + res.price + '</span></p>');

			//$modal.find('.price').html(res.price);
		}

		$modal.find('.brand').html(res.manufacturer);
		$modal.find('.name').html(res.name);
		$modal.find('.description').html(res.description);
		$modal.find('.volume').html(res.volume);
		$modal.find('.product-details-image-wrapper img').attr('src', res.picture_url);
		$modal.find('input[name=itemId]').val(res.id);
		$modal.find('.btn').data('product', res.id);

		$('#productDetailsModal').modal('show');

		return false;
	});
}

$('.product-image').click(function (e) {
	e.preventDefault();

	showProductModal($(e.currentTarget).data('product'));
});

$('.btn-increase').click(function (e) {
	e.preventDefault();

	var qty = $(e.currentTarget).prev().val();

	if (Number.isInteger(Number.parseInt(qty))) {
		$(e.currentTarget).prev().val(Number.parseInt(qty) + 1);
	} else {
		notify('A megadott mennyiség hibás.', 'error');
	};
});

$('.btn-decrease').click(function (e) {
	e.preventDefault();

	var qty = $(e.currentTarget).next().val();

	if (Number.isInteger(Number.parseInt(qty))) {
		if (Number.parseInt(qty) <= 0) {
			notify('A megadott mennyiség nem lehet kisebb mint 1.', 'error');
			return false;
		}

		$(e.currentTarget).next().val(Number.parseInt(qty) - 1);
	} else {
		notify('A megadott mennyiség hibás.', 'error');
	};
});

if ($('#hfSiteProduct').length > 0) {
	showProductModal($('#hfSiteProduct').val());
}

},{}]},{},[1]);
